html,
body {
  padding: 0;
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

@font-face {
font-family: '__goldplayW05Medium_75a1b5';
src: url(/_next/static/media/027bc6fe428c589b-s.p.woff2) format('woff2');
font-display: swap;
}@font-face {font-family: '__goldplayW05Medium_Fallback_75a1b5';src: local("Arial");ascent-override: 90.80%;descent-override: 24.30%;line-gap-override: 0.00%;size-adjust: 100.00%
}.__className_75a1b5 {font-family: '__goldplayW05Medium_75a1b5', '__goldplayW05Medium_Fallback_75a1b5'
}

@font-face {
font-family: '__goldplayW05SemiBold_85c4f8';
src: url(/_next/static/media/f7d96a4bda65486e-s.p.woff2) format('woff2');
font-display: swap;
}@font-face {font-family: '__goldplayW05SemiBold_Fallback_85c4f8';src: local("Arial");ascent-override: 88.60%;descent-override: 25.20%;line-gap-override: 0.00%;size-adjust: 107.56%
}.__className_85c4f8 {font-family: '__goldplayW05SemiBold_85c4f8', '__goldplayW05SemiBold_Fallback_85c4f8'
}

@font-face {
font-family: '__ernestineW05Bold_9ce9d3';
src: url(/_next/static/media/10ceb170633bb990-s.p.woff2) format('woff2');
font-display: swap;
}@font-face {font-family: '__ernestineW05Bold_Fallback_9ce9d3';src: local("Arial");ascent-override: 81.07%;descent-override: 20.56%;line-gap-override: 0.00%;size-adjust: 120.64%
}.__className_9ce9d3 {font-family: '__ernestineW05Bold_9ce9d3', '__ernestineW05Bold_Fallback_9ce9d3'
}

